// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-button {
    align-self: center;
    align-content: center;
    padding: 5px;
    margin-top: 5%;
    background: var(--background-button);
    outline: none;
    border: 2px solid var(--background-button);
    border-radius: 5px;
    color: var(--font-color-button);
}

.submit-button:hover {
    transform: scale(1.1);
}

.submit-form {
    margin-left: 6%;
    display: flex;
    flex-direction: column;
    align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/FormsComponents/InputSubmit/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,cAAc;IACd,oCAAoC;IACpC,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":[".submit-button {\r\n    align-self: center;\r\n    align-content: center;\r\n    padding: 5px;\r\n    margin-top: 5%;\r\n    background: var(--background-button);\r\n    outline: none;\r\n    border: 2px solid var(--background-button);\r\n    border-radius: 5px;\r\n    color: var(--font-color-button);\r\n}\r\n\r\n.submit-button:hover {\r\n    transform: scale(1.1);\r\n}\r\n\r\n.submit-form {\r\n    margin-left: 6%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
