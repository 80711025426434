import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import Button from "react-bootstrap/Button"
import Input from "../FormsComponents/Input";
import SelectInput from "../FormsComponents/SelectInput";
import {TypeEmployeAPI} from "../../Services/TypeEmployeAPI";
import DatePicker from "../DatePicker";
import {EmployeAPI} from "../../Services/EmployeAPI";
import {toast} from "react-toastify";

const InfoPersoEmployeTap = ({user, setUser}) => {
    const [edit, setEdit] = useState(false);
    const [typeEmploye, setTypeEmploye] = useState([]);
    const [optionCivilite, setOptionCivilite] = useState([
        {value : "Mr", label : "Mr"},
        {value : "Mme", label : "Mme"}
    ]);

    const fetchTypeEmployes = async () => {
        let data = await TypeEmployeAPI.getAllTypeEmploye();
        return data.data;

    }

    const setOptionsFetchEmploye = (typeEmploye) => {
        let options = [];
        typeEmploye.map((e) => {
            options.push({value: e, label: e.libelle})
        });
        setTypeEmploye(options)
    }
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        setUser({...user, [name]: value})
    }

    const handleClick = () => {
        setEdit(!edit)
    }

    const handleSubmit = async () => {
        try {
            if(user.dateSortie && user.dateSortie < moment()){
                user.isActif = false;
            }else{
                user.isActif = true;
            }
            const response = await EmployeAPI.updateEmploye(user.id, user);
            toast.success("L'employé à bien été mis à jours")
            setEdit(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchTypeEmployes().then(res => {
            setOptionsFetchEmploye(res)
        })
    }, []);

    return <>
        <div className={"d-flex justify-content-end mb-3"}>
            <Button className={"btn btn-alliance"} variant={"outlined"}
                    onClick={edit == false ? handleClick : handleSubmit}>{edit == false ? "Modifier l'employé" : "Valider"}</Button>
        </div>
        <ul className="list-group">
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}>
                <span className={"info-color mr-1"}>Civilité : </span> {edit == false ? user.civilite :
                <SelectInput  options={optionCivilite} objet={user} setObjet={setUser} value={user.civilite && {value : user.civilite, label : user.civilite}} placeholder={"Civilite  de l'employé"} name={"civilite"} />
                }</li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}>
                <span className={"info-color mr-1"}>Nom : </span> {edit == false ? user.nom :
                <Input type={"text"} name={"nom"} value={user.nom} placeholder={"Nom employé"} setObjet={setUser}
                       objet={user} toUppercase={true}/>}</li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}>
                <span className={"info-color mr-1"}>Prenom : </span> {edit == false ? user.prenom :
                <Input type={"text"} name={"prenom"} value={user.prenom} placeholder={"Prenom employé"}
                       setObjet={setUser} objet={user}  toUppercase={true}/>}
            </li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Fonction : </span> {edit == false ? (user.typeEmploye ? user.typeEmploye.libelle : "") :
                <SelectInput
                    objet={user}
                    setObjet={setUser}
                    name={"typeEmploye"}
                    value={user.typeEmploye && {
                        value: user.typeEmploye,
                        label: user.typeEmploye.libelle
                    }}
                    options={typeEmploye}
                    placeholder={"Fonction de l'employé"}
                />}
            </li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Adresse : </span> {edit == false ? user.adresse :
                <Input type={"text"} name={"adresse"} value={user.adresse} placeholder={"Adresse employé"}
                       setObjet={setUser} objet={user} toUppercase={true}/>}</li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Complement Adresse : </span> {edit == false ? user.complementAdresse ? user.complementAdresse : "Aucun complément adresse" :
                <Input type={"text"} name={"complementAdresse"} value={user.complementAdresse}
                       placeholder={"Complement Adresse de l'employé"} setObjet={setUser} objet={user}  toUppercase={true}/>}</li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}>
                <span className={"info-color mr-1"}>Ville : </span> {edit == false ? user.ville :
                <Input type={"text"} name={"ville"} value={user.ville} placeholder={"Ville employé"} setObjet={setUser}
                       objet={user}  toUppercase={true}/>}
            </li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Code postal : </span> {edit == false ? user.codePostal :
                <Input type={"text"} name={"codePostal"} value={user.codePostal}
                       placeholder={"Code postal de l'employé"} setObjet={setUser} objet={user}  toUppercase={true}/>}</li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Date de naissance : </span> {edit == false ? (moment(user.dateNaissance).format("DD/MM/YYYY")) :
                <DatePicker name={"dateNaissance"} objet={user} setObjet={setUser}
                            value={user.dateNaissance != undefined ? moment(user.dateNaissance) : null}
                            objectIsMoment={false} toUppercase={true}/>}
            </li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Adresse mail : </span> {edit == false ? user.mail ? user.mail : "Aucune adresse mail" :
                <Input type={"email"} name={"mail"} value={user.mail} placeholder={"Email de l'employé"}
                       setObjet={setUser} objet={user}/>}</li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Numéro de sécurité sociale : </span> {edit == false ? user.numeroSecuriteSocial :
                <Input type={"text"} name={"numeroSecuriteSocial"} value={user.numeroSecuriteSocial}
                       placeholder={"Numero Securite Social de l'employé"} setObjet={setUser} objet={user}/>}
            </li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Date d'entrée : </span> {edit == false ? user.dateEntree != undefined ? moment(user.dateEntree).format("DD/MM/YYYY") : "Pas de date d'entrée" :
                <DatePicker name={"dateEntree"} objet={user} setObjet={setUser}
                            value={user.dateEntree != undefined ? moment(user.dateEntree) : null}
                            objectIsMoment={false}/>}
            </li>
            <li className={edit == false ? "list-group-item d-flex" : "list-group-item d-flex justify-content-between"}><span
                className={"info-color mr-1"}>Date de sortie : </span> {edit == false ? user.dateSortie != undefined ? (moment(user.dateSortie).format("DD/MM/YYYY")) : "Pas de date de sortie" :
                <DatePicker name={"dateSortie"} objet={user} setObjet={setUser}
                            value={user.dateSortie != undefined ? moment(user.dateSortie) : null}
                            objectIsMoment={false}/>}
            </li>
        </ul>
    </>;
};

export default InfoPersoEmployeTap;