import React, {useEffect, useState} from 'react';
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import '../../Styles/utilisateurs.css';
import {EmployeAPI} from "../../Services/EmployeAPI";
import Header2Generique from "../../Components/Headers/Header2Generique";
import GenericDataTable from "../../Components/DataTables/GenericDataTable";
import {Visibility} from "@mui/icons-material";
import DotMenuEmployeDataTable from "../../Components/Menus/DotMenuEmployeDataTable";
import ModalUser from "../../Components/Modal/ModalEmploye";
import {useNavigate} from 'react-router-dom';
import TextField from "@mui/material/TextField";
import moment from "moment";
import axios from "axios";
import {URL_API} from "../../Config/url";

const RH = () => {
    const [loading, setLoading] = useState(false);
    const [dataUtilisateurs, setDataUtilisateurs] = useState([]);
    const [employes, setEmployes] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState({});
    const [filteredUtilisateurs, setFilteredUtilisateurs] = useState([]);
    const [rowSelected, setRowSelected] = useState(null);
    const navigate = useNavigate();
    const [file,setFile] = useState();

    const [columnUtilisateurs, setColumnUtilisateurs] = useState([
        {
            id: "nom",
            name: <span className="top-column">Nom</span>,
            selector: row => row.nom,
            sortable: true,

        },
        {
            name: <span className="top-column">Prénom</span>,
            selector: row => row.prenom,
            sortable: true
        },
        {
            name: <span className="top-column">Fonctions</span>,
            selector: row => row.typeEmploye.libelle,
            sortable: true
        },
        {
            name: <span className="top-column">Etat au {moment().format("DD/MM/YYYY")}</span>,
            selector: row => row.isActif ? "Actif" : "Inactif",
            sortable: true
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => (
                <div className="d-flex justify-content-evenly">
                    <Tooltip title={"Voir l'employé"} placement={"right"}>
                        <button onClick={() => {
                            setRowSelected(row);
                        }}>
                            <IconButton>
                                <Visibility color="warning"/>
                            </IconButton>
                        </button>
                    </Tooltip>
                    <DotMenuEmployeDataTable idUser={row.id} user={row} fetchUtilisateur={fetchUtilisateurs}/>
                </div>
            )
        }
    ]);

    const getEtat = async (id) => {
        try {
            const response = await EmployeAPI.getEtat({"id": id})
            return response.data["disponibilité"];
        } catch (e) {
            console.log(e)
        }
    }

    const handleAdd = () => {
        setIsOpen(true);
    };

    const fetchEmployeByName = async () => {
        setFilteredUtilisateurs(dataUtilisateurs.filter((uti) => {
            const nom = uti.nom.toLowerCase();
            const prenom = uti.prenom.toLowerCase();
            const searchTermLower = searchValue.value.toLowerCase();
            return nom.includes(searchTermLower) || prenom.includes(searchTermLower);
        }))
    }

    const navigateToRH = () => {
        let index = employes.findIndex(p => p.id == rowSelected.id)
        navigate("/RH/informations", {state : {id: rowSelected.id, index : index}})
    }

    const fetchUtilisateurs = async () => {
        let dataUsers = await EmployeAPI.getAllEmploye();
        let results = dataUsers;
        for (let i = 0; i < dataUsers.length; i++) {
            // let etat = await getEtat(dataUsers[i].id);
            // dataUsers[i].etat = etat;
            // results.push(dataUsers[i]);
        }
        setEmployes(results.sort((a, b) => a.nom.localeCompare(b.nom)));
        setDataUtilisateurs(results.sort((a, b) => a.nom.localeCompare(b.nom)));
        setFilteredUtilisateurs(results.sort((a, b) => a.nom.localeCompare(b.nom)))
        setLoading(false);
    };

    const testUpload = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        axios
            .post(URL_API+"/upload_excel", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((response) => {
                // handle the response
            })
            .catch((error) => {
                // handle errors
                console.log(error);
            });
    }


    useEffect(() => {
        setLoading(true);
        fetchUtilisateurs().then(r => '');
    }, []);

    useEffect(() => {
        fetchEmployeByName()
    }, [searchValue]);

    useEffect(() => {
        if (rowSelected != null){
            navigateToRH()
        }
    }, [rowSelected]);

    return (
        <div>
            <Header2Generique title={"Liste des employés"} componentRight={
                <TextField
                    label={"Rechercher un Employé : "}
                    sx={{'& .MuiOutlinedInput-root.Mui-focused fieldset': {borderColor: 'var(--orange-color)'}}}
                    variant={"outlined"}
                    value={searchValue.value}
                    name={"value"}
                    onChange={(e) => {
                        const {name, value} = e.currentTarget;
                        setSearchValue({...searchValue, [name] : value})
                    }}
                />
           }/>
            {/*<form onSubmit={testUpload} method={"post"}>*/}
            {/*    <input type={"file"} onChange={(e) => {setFile(e.target.files[0])}}  />*/}
            {/*    <input type="submit"/>*/}
            {/*</form>*/}
            <div className="col-11 m-auto mt-2">
                {loading ? (
                    <div className="text-center">
                        <CircularProgress color={"primary"}/>
                    </div>
                ) : (
                    <GenericDataTable
                        defaultSortFieldId={"nom"}
                        defaultSortAsc={true}
                        data={filteredUtilisateurs}
                        // text={"La création d'employé ce fait via TimeMoto"}
                        setIsOpen={handleAdd}
                        btnText={"Ajouter un Employé"}
                        columnItem={columnUtilisateurs}
                        noDataText={"Aucun Employé trouvé"} theme={"solarized"}
                    />
                )}
            </div>
            <ModalUser
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                fetchUtilisateurs={fetchUtilisateurs}
            />
        </div>
    );
};

export default RH;
