import React, {useState} from 'react';
import HabilitationUser from "../Modal/ModalHabilitation/HabilitationUser";
import {Delete} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {EmployeAPI} from "../../Services/EmployeAPI";
import {toast} from "react-toastify";
import AtelierUser from "../Modal/ModalAtelier/AtelierUser";

const AtelierEmployeTab = ({user, setUser}) => {
    const [modal, setModal] = useState(false);


    return <>
        <div className={"flex justify-content-end"}>
            <button className={"btn btn-add-activity mb-3"} onClick={() => setModal(true)}>
                Changer d'atelier
            </button>
        </div>
        <ul className={"list-group"}>
            {user.atelier ? (
                    <li className={"list-group-item d-flex justify-content-between align-items-center"}>
                        {user.atelier.libelle}
                    </li>
                ) : (
                <li className={"list-group-item"}>Aucun atelier
            pour {user.nom} {user.prenom}</li>
            )}
        </ul>
        <AtelierUser isOpen={modal} setIsOpen={setModal} user={user} setUser={setUser}/>
    </>;
};

export default AtelierEmployeTab;