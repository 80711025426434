import React, {useContext, useEffect, useState} from 'react';
import {EmployeAPI} from "../../Services/EmployeAPI";
import generatePDF, {usePDF} from "react-to-pdf";
import moment from "moment/moment";
import colors from "../../Styles";
import Header2PlanningAtelier from "../../Components/Headers/Header2PlanningAtelier";
import SideBarContext from "../../Context/SideBarContext";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const PlanningAtelier = () => {
    const [dateDebut, setDateDebut] = useState(moment());
    const [dateFin, setDateFin] = useState("2024-10-14 17:00:00");
    const [atelier, setAtelier] = useState(null);
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);
    const {toPDF, targetRef} = usePDF({filename: 'page.pdf'});
    const {setIsOpen} = useContext(SideBarContext);
    const [Options, setOptions] = useState({});

    const styles = {
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            // display: "flex"
        },
        td: {
            border: "1px solid #000",
            padding: "8px",
            textAlign: "center"
        },
        th: {
            border: "1px solid #000",
            padding: "10px",
            textAlign: "center",
            color: colors.white,
        },
        headerRow: {
            backgroundColor: colors.orange,
            border: "1px solid #000",
            padding: "5px",
        },
        row: {
            pageBreakBefore: "always", breakInside: "avoid"
        }
    };

    const generatePDF = () => {
        const input = document.getElementById("pdf-content");

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("l", "mm", "a4");

            const imgWidth = 297; // Largeur du PDF (A4 en mm)
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let position = 0;
            let pageHeight = 210; // Hauteur d'une page A4 en mm

            while (position < imgHeight) {
                pdf.addImage(imgData, "PNG", 0, position * -1, imgWidth, imgHeight);
                position += pageHeight;

                if (position < imgHeight) {
                    pdf.addPage();
                }
            }

            pdf.save(atelier != null ? "PlanningAtelier_" + atelier.atelier.libelle + "_" + dateDebut.format('DD-MM-YYYY') + ".pdf" : "PlanningAtelier.pdf");
        });
    };

    const fetchData = async () => {
        try {
            let date;
            let dateFin;
            if (atelier.atelier.libelle == "Transport Sasu") {
                // Ici la date de début doit etre la date de début de la semaine de la date donnée
                date = moment(dateDebut).startOf('isoWeek').format('YYYY-MM-DD') + " 08:00:00";
                // date = moment(dateDebut).format('YYYY-MM-DD') + " 08:00:00";
                dateFin = moment(dateDebut).endOf('isoWeek').format('YYYY-MM-DD') + " 17:00:00";
            } else {
                date = moment(dateDebut).format('YYYY-MM-DD') + " 08:00:00";
                dateFin = moment(dateDebut).format('YYYY-MM-DD') + " 17:00:00";
            }
            const response = await EmployeAPI.getPlannifForAtelier(date, dateFin, atelier.atelier.id);
            setData(groupedData(response.data));
            // const response = await EmployeAPI.getPlannifForAtelier(date, dateFin, atelier.atelier.id);
            // console.log(response.data)
            // const data = [];
            // response.data.map((item) => {
            //     data.push(groupedData(item));
            // })
            //   setData(data);
        } catch (e) {
            console.error("Erreur lors de la récupération des données:", e);
        }
    }

    function getDaysOfWeek(number, number2) {
        let days = [];
        let week = moment().year(number).week(number2);
        for (let i = 1; i <= 7; i++) {
            days.push(week.day(i).format('YYYY-MM-DD'));
        }
        return days;
    }

    const groupedData = (data) => {
        let result = {};
        if (atelier.atelier.libelle == "Le Maillon C2RI") {
            result = data.reduce((acc, item) => {
                // Créer une clé unique basée sur le nom et prénom
                const key = `${item.em_nom}_${item.em_prenom}`;

                // Si la clé n'existe pas encore, l'initialiser avec un tableau
                if (!acc[key]) {
                    acc[key] = {
                        nom: item.em_nom,
                        prenom: item.em_prenom,
                        affectations: [], // Stocke les différentes affectations
                        cispDebut: item.cisp_date_debut,
                        cispFin: item.cisp_date_fin
                    };
                }

                // Ajouter l'affectation à la liste
                acc[key].affectations.push({
                    equipe: item.ph_equipe,
                    groupeActivite:  item.ga_libelle ,
                    dateDebut: item.date_debut,
                    dateFin: item.date_fin,
                    client: item.ac_localisation == 'EXTERNE' ? item.cl_nom : item.activite ,
                    cispDebut: item.cisp_date_debut,
                    cispFin: item.cisp_date_fin
                });
                return acc;
            }, {});
        } else if (atelier.atelier.libelle == "Transport Sasu") {
            result = data.reduce((acc, item) => {
                // Créer une clé unique basée sur le nom et prénom
                const key = moment.unix(item.date_debut).format('YYYY-MM-DD');

                // Si la clé n'existe pas encore, l'initialiser avec un tableau
                if (!acc[key]) {
                    acc[key] = {
                        date: key,
                        affectations: [], // Stocke les différentes affectations
                    };
                }
                // Ajouter l'affectation à la liste
                acc[key].affectations.push({
                    employe: item.em_nom + " " + item.em_prenom,
                    equipe: item.ph_equipe,
                    activite: item.ac_libelle,
                    date: moment.unix(item.date_debut).format("HH:mm") + " - " + moment.unix(item.date_fin).format("HH:mm")
                });

                return acc;
            }, {});

            //     Vérifier que tout les jours de la semaine sont présents
            const days = getDaysOfWeek(dateDebut.year(), dateDebut.week());
            days.forEach(day => {
                if (!result[day]) {
                    result[day] = [];
                }
            });
            result = Object.keys(result)
                .sort((a, b) => new Date(a) - new Date(b))
                .reduce((acc, key) => {
                    acc[key] = result[key];
                    return acc;
                }, {});

        } else {
            result = data.reduce((acc, item) => {
                // Créer une clé unique basée sur le nom et prénom
                const key = `${item.em_nom}_${item.em_prenom}`;
                // Si la clé n'existe pas encore, l'initialiser avec un tableau
                if (!acc[key]) {
                    acc[key] = {
                        nom: item.em_nom,
                        prenom: item.em_prenom,
                        affectations: [], // Stocke les différentes affectations
                        cispDebut: item.cisp_date_debut,
                        cispFin: item.cisp_date_fin
                    };
                }

                // Ajouter l'affectation à la liste
                acc[key].affectations.push({
                    equipe: item.ph_equipe,
                    groupeActivite: item.ga_libelle,
                    dateDebut: item.date_debut,
                    activite: item.activite,
                    dateFin: item.date_fin,
                    client: item.cl_nom,
                    cispDebut: item.cisp_date_debut,
                    cispFin: item.cisp_date_fin
                });

                return acc;
            }, {});
        }
        return result;
    }

    // function getDaysOfWeek(year, weekNumber) {
    //     const days = [];
    //
    //     // Début de la semaine donnée (Moment.js utilise par défaut dimanche comme premier jour)
    //     const startOfWeek = moment().year(year).week(weekNumber).startOf('week').add(1, 'days'); // Ajuste pour commencer lundi
    //
    //     for (let i = 0; i < 6; i++) { // 6 jours (lundi à samedi)
    //         days.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD'));
    //     }
    //
    //     return days;
    // }

    useEffect(() => {
        // if (atelier !== null && dates.length > 0) {
        if (atelier !== null && atelier.atelier !== null && dateDebut !== null) {

            setOptions( {
                filename: atelier != null ? "PlanningAtelier_" + atelier.atelier.libelle + "_" + dateDebut.format('DD-MM-YYYY') + ".pdf" : "PlanningAtelier.pdf",
                page: {
                    margin: 3,
                    orientation: 'landscape',
                }
            });
            fetchData();
        }
        // }, [atelier, dates]);
    }, [atelier, dateDebut]);

    // useEffect(() => {
    //     setDates(getDaysOfWeek(dateDebut.year(), dateDebut.week()));
    // }, [dateDebut]);

    // Pour le deuxime planning faire un plusieur ul avec des li a l'interieur entoure d'une div en f-flex flex-wrap
    return <>
        <Header2PlanningAtelier dateDebut={dateDebut} setDateDebut={setDateDebut} dateFin={dateFin}
                                setDateFin={setDateFin} atelier={atelier} setAtelier={setAtelier}/>

        {(dateDebut != null && atelier != null) ? <>
                {(atelier.atelier && atelier.atelier.libelle == "Le Maillon C2RI") ? <>
                    <div className={"d-flex justify-content-end mb-5"}>
                        {/*<button className={"btn btn-alliance m-2"} onClick={() => toPDF()}>Télécharger PDF</button>*/}
                        <button className={"btn btn-alliance m-2"}
                                onClick={() => {
                                    // generatePDF(targetRef, Options)
                                    generatePDF()
                                }}>Télécharger PDF
                        </button>
                    </div>
                    <div ref={targetRef} id={"pdf-content"} style={{margin: '10px', paddingBottom: "5vh", maxWidth: "100%", pageBreakBefore: "always", breakInside: "avoid"}} >
                        <table style={styles.table}>
                            {/* En-têtes */}
                            <thead style={styles.headerRow}>
                            <tr style={styles.headerRow}>
                                <th style={styles.th} colSpan={10}>Planning Affichage Atelier {atelier.atelier.libelle}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Semaine</th>
                                <th style={styles.th} colSpan={9}>{dateDebut.week()}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Date</th>
                                <th style={styles.th} colSpan={9}>{dateDebut.format("DD/MM/YYYY")}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Jour</th>
                                <th style={styles.th} colSpan={9}>
                                    {dateDebut.day() == 0 && "Dimanche"}
                                    {dateDebut.day() == 1 && "Lundi"}
                                    {dateDebut.day() == 2 && "Mardi"}
                                    {dateDebut.day() == 3 && "Mercredi"}
                                    {dateDebut.day() == 4 && "Jeudi"}
                                    {dateDebut.day() == 5 && "Vendredi"}
                                    {dateDebut.day() == 6 && "Samedi"}
                                </th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Nom</th>
                                <th style={styles.th}>Prénom</th>
                                <th style={styles.th}>Affectation</th>
                                <th style={styles.th} colSpan={2}>Activité</th>
                                <th style={styles.th} colSpan={2}>Horaires</th>
                                <th style={styles.th}>CISP</th>
                            </tr>
                            </thead>
                            {/* Corps du tableau */}
                            <tbody>
                            {data && Object.keys(data).map((item, index) => {
                                return data[item].affectations && data[item].affectations.map((affectation, idx) => (
                                    <tr key={idx} style={styles.row}>
                                        {/* Afficher le nom et prénom seulement sur la première ligne des affectations */}
                                        {idx === 0 && (
                                            <>
                                                <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                    {data[item].nom}
                                                </td>
                                                <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                    {data[item].prenom}
                                                </td>
                                            </>
                                        )}
                                        <td style={styles.td}>{affectation.equipe}</td>
                                        <td style={styles.td}>{affectation.groupeActivite}</td>
                                        <td style={styles.td}>{affectation.client}</td>
                                        <td style={styles.td}>
                                            {moment.unix(affectation.dateDebut.toString().substring(0, 10)).format("HH:mm")}
                                        </td>
                                        <td style={styles.td}>
                                            {moment.unix(affectation.dateFin.toString().substring(0, 10)).format("HH:mm")}
                                        </td>
                                        {idx === 0 && (
                                            <>
                                                <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                    {affectation.cispDebut && moment.unix(affectation.cispDebut.toString().substring(0, 10)).format("HH:mm") + "-" + moment.unix(affectation.cispFin.toString().substring(0, 10)).format("HH:mm")}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))
                            })}
                            </tbody>
                        </table>
                    </div>
                </> : (atelier.atelier && (atelier.atelier.libelle == "Ressourcerie Anzin" || atelier.atelier.libelle == "Ressourcerie Landrecies" || atelier.atelier.libelle == "Ressourcerie Bavay" || atelier.atelier.libelle == "Maillon & Co")) ? <>
                    <div className={"d-flex justify-content-end"}>
                        {/*<button className={"btn btn-alliance m-2"} onClick={() => toPDF()}>Télécharger PDF</button>*/}
                        <button className={"btn btn-alliance m-2"}
                                onClick={() => generatePDF(targetRef, Options)}>Télécharger PDF
                        </button>
                    </div>
                    <div ref={targetRef} id={"pdf-content"}  style={{margin: '10px', paddingBottom: "5vh", pageBreakBefore: "always", breakInside: "avoid"}}>
                        <table style={styles.table}>
                            {/* En-têtes */}
                            <thead style={styles.headerRow}>
                            <tr style={styles.headerRow}>
                                <th style={styles.th} colSpan={10}>Planning Affichage
                                    Atelier {atelier.atelier.libelle}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Semaine</th>
                                <th style={styles.th} colSpan={9}>{dateDebut.week()}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Date</th>
                                <th style={styles.th} colSpan={9}>{dateDebut.format("DD/MM/YYYY")}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Jour</th>
                                <th style={styles.th} colSpan={9}>
                                    {dateDebut.day() == 0 && "Dimanche"}
                                    {dateDebut.day() == 1 && "Lundi"}
                                    {dateDebut.day() == 2 && "Mardi"}
                                    {dateDebut.day() == 3 && "Mercredi"}
                                    {dateDebut.day() == 4 && "Jeudi"}
                                    {dateDebut.day() == 5 && "Vendredi"}
                                    {dateDebut.day() == 6 && "Samedi"}
                                </th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Nom</th>
                                <th style={styles.th}>Prénom</th>
                                <th style={styles.th}>Affectation</th>
                                <th style={styles.th} colSpan={2}>Activité</th>
                                <th style={styles.th} colSpan={2}>Horaires</th>
                                <th style={styles.th}>CISP</th>
                            </tr>
                            </thead>
                            {/* Corps du tableau */}
                            <tbody>
                            {data && Object.keys(data).map((item, index) => {
                                return data[item].affectations && data[item].affectations.map((affectation, idx) => (
                                    <tr key={idx} style={index % 2 === 0 ? {} : styles.row}>
                                        {/* Afficher le nom et prénom seulement sur la première ligne des affectations */}
                                        {idx === 0 && (
                                            <>
                                                <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                    {data[item].nom}
                                                </td>
                                                <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                    {data[item].prenom}
                                                </td>
                                            </>
                                        )}
                                        <td style={styles.td}>{affectation.equipe ? affectation.equipe : "Pas d'équipe"}</td>
                                        <td style={styles.td}>{affectation.groupeActivite}</td>
                                        <td style={styles.td}>{affectation.activite}</td>
                                        <td style={styles.td}>
                                            {affectation.dateDebut && moment.unix(affectation.dateDebut.toString().substring(0, 10)).format("HH:mm")}
                                        </td>
                                        <td style={styles.td}>
                                            {affectation.dateFin && moment.unix(affectation.dateFin.toString().substring(0, 10)).format("HH:mm")}
                                        </td>
                                        {idx === 0 && (
                                            <>
                                                <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                    {affectation.cispDebut && moment.unix(affectation.cispDebut.toString().substring(0, 10)).format("HH:mm") + "-" + moment.unix(affectation.cispFin.toString().substring(0, 10)).format("HH:mm")}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))
                            })}
                            </tbody>
                        </table>
                    </div>
                </> : (atelier.atelier && atelier.atelier.libelle == "Transport Sasu") ? <>
                    <div className={"d-flex justify-content-end"}>
                        {/*<button className={"btn btn-alliance m-2"} onClick={() => toPDF()}>Télécharger PDF</button>*/}
                        <button className={"btn btn-alliance m-2"}
                                onClick={() => {
                                    setIsOpen(false)
                                    setTimeout(() => {
                                        generatePDF(targetRef, Options)
                                    }, 200)
                                }}>Télécharger PDF
                        </button>
                    </div>
                    <div ref={targetRef} id={"pdf-content"} style={{margin: '10px', paddingBottom: "5vh", pageBreakBefore: "always", breakInside: "avoid"}}>
                        <table style={styles.table}>
                            {/* En-têtes */}
                            <thead style={styles.headerRow}>
                            <tr style={styles.headerRow}>
                                <th style={styles.th} colSpan={21}>Planning Affichage
                                    Atelier {atelier.atelier.libelle}</th>
                            </tr>
                            <tr style={styles.headerRow}>
                                <th style={styles.th}>Semaine</th>
                                <th style={styles.th} colSpan={20}>{dateDebut.week()}</th>
                            </tr>
                            <tr>
                                <th style={styles.th} rowSpan={2}>
                                    Employé
                                </th>
                                {Object.keys(data).sort((a, b) => new Date(a) - new Date(b)).map((date) => (
                                    <th key={date} style={{...styles.th, ...styles.headerRow}} colSpan={2}>
                                        {moment(date).format("dddd")}
                                    </th>
                                ))}
                            </tr>
                            <tr style={styles.headerRow}>
                                {Object.keys(data).sort((a, b) => new Date(a) - new Date(b)).map((date) => {
                                        return <React.Fragment key={"header-" + date}>
                                            <th style={styles.th}>Activité</th>
                                            <th style={styles.th}>Horaires</th>
                                        </React.Fragment>
                                    }
                                )}

                            </tr>
                            </thead>
                            {/* Corps du tableau */}
                            <tbody>

                            {Array.from({length: Math.max(...Object.values(data).map(d => d.affectations ? d.affectations.length : 0))}).map((_, rowIndex) => {
                                return <tr key={rowIndex}>
                                    {Object.keys(data).sort((a, b) => new Date(a) - new Date(b)).map((date, index) => {
                                        const employee = data[date].affectations?.[rowIndex];
                                        if (employee) {
                                            return <React.Fragment key={`${date}-${rowIndex}`}>
                                                {index === 0 && (
                                                    <td style={styles.td}>
                                                        {employee.employe}
                                                    </td>
                                                )}
                                                <td style={styles.td}>
                                                    {employee.activite}
                                                </td>
                                                <td style={styles.td}>
                                                    {employee.date}
                                                </td>
                                            </React.Fragment>
                                        } else {
                                            let empl = "";
                                            if(data[date].length == 0){
                                                let newDate = moment(date).add(1, 'days').format("YYYY-MM-DD");
                                                if (data[newDate] && data[newDate].affectations?.[rowIndex]) {
                                                    empl = data[newDate].affectations?.[rowIndex];
                                                }
                                            }
                                            return <React.Fragment key={`${date}-${rowIndex}`}>

                                                {index === 0 && <>
                                                    <td style={styles.td}>
                                                        {empl.employe}
                                                    </td>
                                                </>}
                                                <td style={styles.td} colSpan={2}>Aucune affectation</td>
                                            </React.Fragment>
                                        }
                                    })}
                                </tr>
                            })}
                            {/*{Object.keys(data).sort((a, b) => new Date(a) - new Date(b)).map((date, index) => {*/}
                            {/*    return data[date].affectations ? data[date].affectations.map((employee, empIndex) => {*/}
                            {/*        return <tr key={`${date}-${empIndex}`}>*/}
                            {/*            {empIndex === 0 && (*/}
                            {/*                <th style={{...styles.th, ...styles.headerRow}}*/}
                            {/*                    rowSpan={data[date].affectations.length}>*/}
                            {/*                    {moment(date).format("dddd")}*/}
                            {/*                </th>*/}
                            {/*            )}*/}
                            {/*            <td style={styles.td}>{employee.employe}</td>*/}
                            {/*            <td style={styles.td}>{employee.equipe}</td>*/}
                            {/*            <td style={styles.td}>{employee.date}</td>*/}
                            {/*        </tr>*/}
                            {/*    }) : <tr>*/}
                            {/*        <th style={{...styles.th, ...styles.headerRow}}*/}
                            {/*            rowSpan={1}>*/}
                            {/*            {moment(date).format("dddd")}*/}
                            {/*        </th>*/}
                            {/*        <td style={styles.td} colSpan={3}>Aucune affectation</td>*/}
                            {/*    </tr>*/}

                            {/*})}*/}
                            </tbody>
                        </table>
                    </div>
                    {/*<h3 className={"text-center"}>En cours de dev</h3>*/}
                </> : <h3 className={"text-center"}>Aucune données trouvé</h3>}
            </>
            : <h3 className={"text-center"}>Veuillez selectionnez une date et un atelier</h3>}
    </>

};

export default PlanningAtelier;