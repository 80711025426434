import axios from "axios";
import {ATELIER_API} from "../Config/url";

export class AtelierAPI {
    static async getAll() {
        return axios.get(ATELIER_API);
    }

    static async delete(id) {
        return axios.delete(ATELIER_API + '/' + id);
    }

    static async create(faite) {
        return axios.post(ATELIER_API, faite);
    }

    static async update(faite) {
        return axios.put(ATELIER_API + '/' + faite.id, faite);
    }

}