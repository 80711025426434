import React from 'react';
import SvgIcon from "@mui/material/SvgIcon";
import Color from "../../../Styles";
import Select from "react-select";

const SelectInput = ({
                         flex,
                         label,
                         classNameInput,
                         name,
                         required,
                         icon,
                         classNameLabel,
                         options,
                         value,
                         setObjet,
                         objet,
                         placeholder,
                         setSelectRef = () => {
                         },
                         disabled,
                         isMulti = false,
                         onInputChange = () => {},
                            onChange = () => {}
                     }) => {

    const handleChange = (event) => {
        if (event != null && isMulti == true) {
            if (name != undefined) {
                setObjet({...objet, [name]: event})
            } else {
                setObjet(event)
            }
        } else if (event != null) {
            if (name != undefined) {
                setObjet({...objet, [name]: event.value})
            } else {
                setObjet(event)
            }
        } else if (event == null && isMulti == true){
            setObjet({})
        }else {
            if (name != undefined) {
                setObjet({...objet, [name]: null})
            } else {
                setObjet({})
            }
        }
    }

    const handleClear = () => {
        if (name != undefined) {
            setObjet({...objet, [name]: {}})
        } else {
            setObjet({})
        }
    }


    return <>
        {flex == "column" ? <div className={"form-group d-flex-column"}>
            {(label != "" && label != undefined) && <>
                <label htmlFor={name} className={"form-label mt-1 " + classNameLabel}
                       key={name}>{label}{required && <> *</>} :</label>
            </>}
            {icon != null && icon != undefined ? <div className={"group-input"}>
                    <div className={"input-icon col-2"}>
                        <SvgIcon sx={{color: Color.grey, height: 1}}>
                            {icon}
                        </SvgIcon>
                    </div>
                    <div className={"col-11 " + classNameInput}>
                        <Select isMulti={isMulti} ref={refe => {
                            setSelectRef(refe)
                        }} options={options} isClearable={true} className={"select"} onChange={handleChange}
                                placeholder={placeholder} onClear={handleClear} isDisabled={disabled != undefined ? disabled : false} value={value}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({...base, zIndex: 9999})
                                }} required={required} onInputChange={onInputChange}/>
                    </div>
                </div> :
                <div className={"col-12 " + classNameInput}>
                    <Select required={required} isMulti={isMulti} ref={refe => {
                        setSelectRef(refe)
                    }} options={options} isClearable={true} className={"select"} onChange={handleChange}
                            placeholder={placeholder} onClear={handleClear} isDisabled={disabled != undefined ? disabled : false} value={value}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: base => ({...base, zIndex: 9999})
                            }}  onInputChange={onInputChange}/>
                </div>}
        </div> : <div className={"form-group d-flex"}>
            {(label != "" && label != undefined) && <>
                <label htmlFor={name} className={"form-label col-5 mt-1 " + classNameLabel}
                       key={name}>{label}{required && <> *</>} :</label>
            </>}
            {icon != null && icon != undefined ? <div className={"group-input"}>
                    <div className={"input-icon col-2"}>
                        <SvgIcon sx={{color: Color.grey, height: 1}}>
                            {icon}
                        </SvgIcon>
                    </div>
                    <div className={"col-11 " + classNameInput}>
                        <Select required={required} isMulti={isMulti} ref={refe => {
                            setSelectRef(refe)
                        }} options={options} isClearable={true} className={"select"} onChange={handleChange}
                                placeholder={placeholder} onClear={handleClear} isDisabled={disabled != undefined ? disabled : false} value={value}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({...base, zIndex: 9999})
                                }}  onInputChange={onInputChange}/>
                    </div>
                </div> :
                <div className={"col-12 " + classNameInput}>
                    <Select required={required} isMulti={isMulti} ref={refe => {
                        setSelectRef(refe)
                    }} options={options} isClearable={true} className={"select"} onChange={handleChange}
                            placeholder={placeholder} onClear={handleClear} isDisabled={disabled != undefined ? disabled : false} value={value}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: base => ({...base, zIndex: 9999})
                            }}  onInputChange={onInputChange}/>
                </div>}
        </div>}
    </>;
};

export default SelectInput;