import React, {useEffect, useRef, useState} from 'react';
import {EmployeAPI} from "../../Services/EmployeAPI";
import generatePDF, {usePDF, Options} from "react-to-pdf";
import moment from "moment/moment";
import colors from "../../Styles";
import Header2PlanningAtelier from "../../Components/Headers/Header2PlanningAtelier";
const PlanningAtelier = () => {
    const [dateDebut, setDateDebut] = useState(moment());
    const [dateFin, setDateFin] = useState("2024-10-14 17:00:00");
    const [atelier, setAtelier] = useState(null);
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);
    const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

    const styles = {
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            // display: "flex"
        },
        td : {
            border:"1px solid #000",
            padding:"8px",
            textAlign : "center"
        },
        th : {
            border:"1px solid #000",
            padding:"10px",
            textAlign : "center",
            color : colors.white,
        },
        headerRow: {
            backgroundColor: colors.orange,
            border:"1px solid #000",
            padding:"5px",
        }
    };

    const Options = {
        filename: atelier != null ? "PlanningAtelier_"+atelier.atelier.libelle+"_"+dateDebut.format('DD-MM-YYYY')+".pdf" : "PlanningAtelier.pdf",
        page: {
            margin: 3
        }
    };

    const fetchData = async () => {
      try {
        const date = moment(dateDebut).format('YYYY-MM-DD') + " 08:00:00";
          const dateFin = moment(dateDebut).format('YYYY-MM-DD') + " 17:00:00";
        const response = await EmployeAPI.getPlannifForAtelier(date, dateFin, atelier.atelier.id);
        setData(groupedData(response.data));
          // const response = await EmployeAPI.getPlannifForAtelier(date, dateFin, atelier.atelier.id);
          // console.log(response.data)
          // const data = [];
          // response.data.map((item) => {
          //     data.push(groupedData(item));
          // })
          //   setData(data);
      }  catch (e) {
          console.error("Erreur lors de la récupération des données:", error);
      }
    }

    const groupedData = (data) => {
        return data.reduce((acc, item) => {
            // Créer une clé unique basée sur le nom et prénom
            const key = `${item.em_nom}_${item.em_prenom}`;

            // Si la clé n'existe pas encore, l'initialiser avec un tableau
            if (!acc[key]) {
                acc[key] = {
                    nom: item.em_nom,
                    prenom: item.em_prenom,
                    affectations: [], // Stocke les différentes affectations
                };
            }

            // Ajouter l'affectation à la liste
            acc[key].affectations.push({
                equipe: item.ph_equipe,
                groupeActivite: item.ga_libelle,
                activite: item.activite,
                dateDebut: item.pac_datedebut,
                dateFin: item.pac_datefin,
                cispDebut : item.cisp_date_debut,
                cispFin : item.cisp_date_fin
            });

            return acc;
        }, {});
    }

    // function getDaysOfWeek(year, weekNumber) {
    //     const days = [];
    //
    //     // Début de la semaine donnée (Moment.js utilise par défaut dimanche comme premier jour)
    //     const startOfWeek = moment().year(year).week(weekNumber).startOf('week').add(1, 'days'); // Ajuste pour commencer lundi
    //
    //     for (let i = 0; i < 6; i++) { // 6 jours (lundi à samedi)
    //         days.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD'));
    //     }
    //
    //     return days;
    // }

    useEffect(() => {
        // if (atelier !== null && dates.length > 0) {
        if (atelier !== null && dateDebut !== null) {
            fetchData();
        }
    // }, [atelier, dates]);
    }, [atelier, dateDebut]);

    // useEffect(() => {
    //     setDates(getDaysOfWeek(dateDebut.year(), dateDebut.week()));
    // }, [dateDebut]);

    // Pour le deuxime planning faire un plusieur ul avec des li a l'interieur entoure d'une div en f-flex flex-wrap
    return <>
        <Header2PlanningAtelier dateDebut={dateDebut} setDateDebut={setDateDebut} dateFin={dateFin}
                                setDateFin={setDateFin} atelier={atelier} setAtelier={setAtelier}/>

        {(dateDebut != null && atelier !=null ) ? Object.keys(data).length > 0 ? <>
                <div className={"d-flex justify-content-end"}>
                    {/*<button className={"btn btn-alliance m-2"} onClick={() => toPDF()}>Télécharger PDF</button>*/}
                    <button className={"btn btn-alliance m-2"} onClick={() => generatePDF(targetRef, Options)}>Télécharger PDF</button>
                </div>
                <div ref={targetRef} style={{margin: '10px'}}>
                    <table style={styles.table}>
                        {/* En-têtes */}
                        <thead style={styles.headerRow}>
                        <tr style={styles.headerRow}>
                            <th style={styles.th} colSpan={10}>Planning Affichage Atelier {atelier.atelier.libelle}</th>
                        </tr>
                        <tr style={styles.headerRow}>
                            <th style={styles.th}>Semaine</th>
                            <th style={styles.th} colSpan={9}>{dateDebut.week()}</th>
                        </tr>
                        <tr style={styles.headerRow}>
                            <th style={styles.th}>Date</th>
                            <th style={styles.th} colSpan={9}>{dateDebut.format("DD/MM/YYYY")}</th>
                        </tr>
                        <tr style={styles.headerRow}>
                            <th style={styles.th}>Jour</th>
                            <th style={styles.th} colSpan={9}>
                                {dateDebut.day() == 0 && "Dimanche"}
                                {dateDebut.day() == 1 && "Lundi"}
                                {dateDebut.day() == 2 && "Mardi"}
                                {dateDebut.day() == 3 && "Mercredi"}
                                {dateDebut.day() == 4 && "Jeudi"}
                                {dateDebut.day() == 5 && "Vendredi"}
                                {dateDebut.day() == 6 && "Samedi"}
                            </th>
                        </tr>
                        <tr style={styles.headerRow}>
                            <th style={styles.th}>Nom</th>
                            <th style={styles.th}>Prénom</th>
                            <th style={styles.th}>Affectation</th>
                            <th style={styles.th} colSpan={2}>Activité</th>
                            <th style={styles.th} colSpan={2}>Horaires</th>
                            <th style={styles.th}>CISP</th>
                        </tr>
                        </thead>
                        {/* Corps du tableau */}
                        <tbody>
                        {data && Object.keys(data).map((item, index) => {
                            return data[item].affectations.map((affectation, idx) => (
                                <tr key={idx} style={index % 2 === 0 ? {} : styles.row}>
                                    {/* Afficher le nom et prénom seulement sur la première ligne des affectations */}
                                    {idx === 0 && (
                                        <>
                                            <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                {data[item].nom}
                                            </td>
                                            <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                {data[item].prenom}
                                            </td>
                                        </>
                                    )}
                                    <td style={styles.td}>{affectation.equipe}</td>
                                    <td style={styles.td}>{affectation.groupeActivite}</td>
                                    <td style={styles.td}>{affectation.activite}</td>
                                    <td style={styles.td}>
                                        {moment.unix(affectation.dateDebut.toString().substring(0, 10)).format("HH:mm")}
                                    </td>
                                    <td style={styles.td}>
                                        {moment.unix(affectation.dateFin.toString().substring(0, 10)).format("HH:mm")}
                                    </td>
                                    {idx === 0 && (
                                        <>
                                            <td rowSpan={data[item].affectations.length} style={styles.td}>
                                                {affectation.cispDebut && moment.unix(affectation.cispDebut.toString().substring(0, 10)).format("HH:mm") + "-" + moment.unix(affectation.cispFin.toString().substring(0, 10)).format("HH:mm")}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))
                        })}
                        </tbody>
                    </table>
                </div>
            </>
            : <h3 className={"text-center"}>Aucune données trouvé</h3>  : <h3 className={"text-center"}>Veuillez
            selectionnez une date et un atelier</h3> }

        {/*<div className={"d-flex justify-content-end"}>*/}
        {/*    /!*<button className={"btn btn-alliance m-2"} onClick={() => toPDF()}>Télécharger PDF</button>*!/*/}
        {/*    <button className={"btn btn-alliance m-2"} onClick={() => generatePDF(targetRef, Options)}>Télécharger PDF*/}
        {/*    </button>*/}
        {/*</div>*/}
        {/*<div ref={targetRef} style={{margin: '10px'}}>*/}
        {/*    {(dateDebut != null && atelier != null) ? data.map((currentData) => {*/}
        {/*        console.log(Object.keys(currentData, currentData)[0])*/}
        {/*        return Object.keys(currentData).length > 0 ? <>*/}
        {/*                <table style={styles.table}>*/}
        {/*                    /!* En-têtes *!/*/}
        {/*                    <thead style={styles.headerRow}>*/}
        {/*                    <tr style={styles.headerRow}>*/}
        {/*                        <th style={styles.th} colSpan={10}>Planning Affichage Atelier {atelier.atelier.libelle}</th>*/}
        {/*                    </tr>*/}
        {/*                    <tr style={styles.headerRow}>*/}
        {/*                        <th style={styles.th}>Semaine</th>*/}
        {/*                        <th style={styles.th} colSpan={9}>{dateDebut.week()}</th>*/}
        {/*                    </tr>*/}
        {/*                    <tr style={styles.headerRow}>*/}
        {/*                        <th style={styles.th}>Date</th>*/}
        {/*                        <th style={styles.th} colSpan={9}>{dateDebut.format("DD/MM/YYYY")}</th>*/}
        {/*                    </tr>*/}
        {/*                    <tr style={styles.headerRow}>*/}
        {/*                        <th style={styles.th}>Jour</th>*/}
        {/*                        <th style={styles.th} colSpan={9}>*/}
        {/*                            {dateDebut.day() == 0 && "Dimanche"}*/}
        {/*                            {dateDebut.day() == 1 && "Lundi"}*/}
        {/*                            {dateDebut.day() == 2 && "Mardi"}*/}
        {/*                            {dateDebut.day() == 3 && "Mercredi"}*/}
        {/*                            {dateDebut.day() == 4 && "Jeudi"}*/}
        {/*                            {dateDebut.day() == 5 && "Vendredi"}*/}
        {/*                            {dateDebut.day() == 6 && "Samedi"}*/}
        {/*                        </th>*/}
        {/*                    </tr>*/}
        {/*                    <tr style={styles.headerRow}>*/}
        {/*                        <th style={styles.th}>Nom</th>*/}
        {/*                        <th style={styles.th}>Prénom</th>*/}
        {/*                        <th style={styles.th}>Affectation</th>*/}
        {/*                        <th style={styles.th} colSpan={2}>Activité</th>*/}
        {/*                        <th style={styles.th} colSpan={2}>Horaires</th>*/}
        {/*                        <th style={styles.th}>CISP</th>*/}
        {/*                    </tr>*/}
        {/*                    </thead>*/}
        {/*                    /!* Corps du tableau *!/*/}
        {/*                    <tbody>*/}
        {/*                    {currentData && Object.keys(currentData).map((item, index) => {*/}
        {/*                        return currentData[item].affectations.map((affectation, idx) => (*/}
        {/*                            <tr key={idx} style={index % 2 === 0 ? {} : styles.row}>*/}
        {/*                                /!* Afficher le nom et prénom seulement sur la première ligne des affectations *!/*/}
        {/*                                {idx === 0 && (*/}
        {/*                                    <>*/}
        {/*                                        <td rowSpan={currentData[item].affectations.length} style={styles.td}>*/}
        {/*                                            {currentData[item].nom}*/}
        {/*                                        </td>*/}
        {/*                                        <td rowSpan={currentData[item].affectations.length} style={styles.td}>*/}
        {/*                                            {currentData[item].prenom}*/}
        {/*                                        </td>*/}
        {/*                                    </>*/}
        {/*                                )}*/}
        {/*                                <td style={styles.td}>{affectation.equipe}</td>*/}
        {/*                                <td style={styles.td}>{affectation.groupeActivite}</td>*/}
        {/*                                <td style={styles.td}>{affectation.activite}</td>*/}
        {/*                                <td style={styles.td}>*/}
        {/*                                    {moment.unix(affectation.dateDebut.toString().substring(0, 10)).format("HH:mm")}*/}
        {/*                                </td>*/}
        {/*                                <td style={styles.td}>*/}
        {/*                                    {moment.unix(affectation.dateFin.toString().substring(0, 10)).format("HH:mm")}*/}
        {/*                                </td>*/}
        {/*                                {idx === 0 && (*/}
        {/*                                    <>*/}
        {/*                                        <td rowSpan={currentData[item].affectations.length} style={styles.td}>*/}
        {/*                                            {affectation.cispDebut && moment.unix(affectation.cispDebut.toString().substring(0, 10)).format("HH:mm") + "-" + moment.unix(affectation.cispFin.toString().substring(0, 10)).format("HH:mm")}*/}
        {/*                                        </td>*/}
        {/*                                    </>*/}
        {/*                                )}*/}
        {/*                            </tr>*/}
        {/*                        ))*/}
        {/*                    })}*/}
        {/*                    </tbody>*/}
        {/*                </table>*/}
        {/*            </>*/}

        {/*            : <h3 className={"text-center"}>Aucune données trouvé</h3>*/}
        {/*    }) : <h3 className={"text-center"}>Veuillez*/}
        {/*        selectionnez une date et un atelier</h3>}*/}
        {/*</div>*/}


        </>

            };

            export default PlanningAtelier;