// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container, html, .login {
    background: var(--background-main);
}

.login {
    padding-top: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.login .form {
    display: flex;
    flex-direction: column;
    width: 45%;
    border-radius: 15px;
    justify-content: center;
    border: 1px solid var(--orange-color);
    padding: 5%;
    align-content: center;
    align-items: center;
}

.login .logo {
    width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Styles/login.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,mBAAmB;IACnB,uBAAuB;IACvB,qCAAqC;IACrC,WAAW;IACX,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".container, html, .login {\r\n    background: var(--background-main);\r\n}\r\n\r\n.login {\r\n    padding-top: 5%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    align-self: center;\r\n}\r\n\r\n.login .form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 45%;\r\n    border-radius: 15px;\r\n    justify-content: center;\r\n    border: 1px solid var(--orange-color);\r\n    padding: 5%;\r\n    align-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.login .logo {\r\n    width: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
