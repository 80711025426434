import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../../../Styles/ModalForm.css';
import Button from "react-bootstrap/Button"

import {CircularProgress} from "@mui/material";
import {HabilitationAPI} from "../../../Services/HabilitationAPI";
import {modalStyle} from "../../../Styles/ModalStyle";
import SelectInput from "../../FormsComponents/SelectInput";
import {AtelierAPI} from "../../../Services/AtelierAPI";
import {EmployeAPI} from "../../../Services/EmployeAPI";
import {toast} from "react-toastify";

const AtelierUser = ({isOpen, setIsOpen, user, setUser}) => {
    const [loading, setLoading] = useState(false);
    const [atelier, setAtelier] = useState({});
    const [ateliers, setAteliers] = useState([])
    const [ajoutHabilitation, setAjoutHabilitation] = useState(false);
    const [ajout, setAjout] = useState(false);


    const fetchAtelier = async () => {
        try {
            const response = await AtelierAPI.getAll();
            return response.data;
        }catch (e) {
            console.error("Erreur lors de la récupération des ateliers:", e);
        }
    }


    const setOptionsSelectAtelier = (atelier) => {
        let options = [];
        atelier.map((a, index) => {
            options.push({value: a, label: a.libelle});
        });
        setAteliers(options);
    };

    const handleAdd = async () => {
        try {
            user.atelier = atelier.value;
            const response = await EmployeAPI.updateEmploye(user.id, user);
            toast.success("Atelier modifié avec succès");
            setUser({...user, "atelier": atelier.value});
            setIsOpen(false);
        }catch (e) {
            console.error("Erreur lors de l'ajout de l'atelier:", e);
        }
    }

    useEffect(() => {
        fetchAtelier().then(atelier => setOptionsSelectAtelier(atelier));
    }, []);

    useEffect(() => {
        if (user.atelier){
            setAtelier({value: user.atelier, label: user.atelier.libelle});
        }
    }, [user]);
    return (<>
            <Modal open={isOpen}
               onClose={() => {
                   setIsOpen(false)
               }}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h5" component="h2"
                            className={"d-flex justify-content-center p-2 border-bottom"}>
                    Changer d'atelier
                </Typography>
                <div className={"form-modal flex flex-column gap-2"}>
                    {loading ? (
                        <div className={"text-center"}>
                            <CircularProgress color={"primary"}/>
                        </div>
                    ) : (
                        <>
                            <SelectInput flex={"row"} placeholder={"Atelier"} objet={atelier}
                                         setObjet={setAtelier}
                                         value={atelier}
                                         options={ateliers} label={"Atelier"}/>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex justify-content-between col-3 me-2 mt-2">
                                    <Button variant={"contained"} color={"error"} className={"btn m-2"}
                                            onClick={() => {setIsOpen(false)}} type="button">
                                        Annuler
                                    </Button>
                                    <Button className={"btn btn-add-activity m-2"} variant={"outlined"}
                                            color={"primary"} type="submit" onClick={handleAdd}>
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Box>
        </Modal>
    </>
);
};

export default AtelierUser;
