import React, {useEffect, useState} from 'react';
import {Close} from '@mui/icons-material';
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from '@mui/material/Tooltip';
import DatePicker from "../../DatePicker";
import {AtelierAPI} from "../../../Services/AtelierAPI";
import SelectInput from "../../FormsComponents/SelectInput";

const Header2PlanningAtelier = ({
                                         dateDebut,
                                         setDateDebut,
                                         dateFin,
                                         setDateFin,
                                        atelier,
                                        setAtelier

                                     }) => {

    const [pickerKeyDateDebut, setPickerKeyDateDebut] = useState(null);
    const [pickerKeyDateFin, setPickerKeyDateFin] = useState(null);
    const [options, setOptions] = useState([]);
    const handleReset = () => {
        setPickerKeyDateFin(Math.random().toString());
        setPickerKeyDateDebut(Math.random().toString());
    }

    const fetchAtelier = async () => {
        try {
            const response = await AtelierAPI.getAll();
            const options = [];
            response.data.map((atelier) => {
                options.push({value: atelier, label: atelier.libelle})
            });
            setOptions(options);
        } catch (e) {
            console.error("Erreur lors de la récupération des ateliers:", e);
        }
    }

    useEffect(() => {
        fetchAtelier();
    }, []);

    return <div className={"header2"}>
        <div className={"entete"}>
            <h1 className={"m-2 text-center"}>Planning Group (Semaine {dateDebut.week()})</h1>
        </div>
        <div className={"box"}>
            <div className={"d-flex justify-content-between"}>
                <div className={"d-flex col-10 justify-content-around"}>
                    <div className={"col-3 mr-1"}>
                        <DatePicker label={"Date Début"} name={"date"}
                                    objet={dateDebut}
                                    value={dateDebut}
                                    setObjet={setDateDebut} key={pickerKeyDateDebut}/>
                    </div>
                    {/*<div className={"col-2 mr-1"}>*/}
                    {/*    <DatePicker label={"Date Fin"} name={"date"}*/}
                    {/*                objet={dateFin}*/}
                    {/*                setObjet={setDateFin} key={pickerKeyDateFin}/>*/}
                    {/*</div>*/}
                    <div className={"col-4 mr-1"}>
                        <SelectInput name={"atelier"} objet={atelier} setObjet={setAtelier}  options={options} placeholder={"Choisir un atelier"}/>
                    </div>
                </div>
                <div className={"d-flex col-2 justify-content-end"}>
                    <Tooltip title={"Vider les filtres"}>
                        <button className="btn btn-refresh mr-3" onClick={() => handleReset()}>
                            <SvgIcon sx={{color: "#FFFFFF"}}>
                                <Close/>
                            </SvgIcon>
                        </button>
                    </Tooltip>
                </div>

            </div>
        </div>

    </div>
};

export default Header2PlanningAtelier;