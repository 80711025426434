import React, {useContext, useEffect} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import jwtDecode from "jwt-decode";
import Login from "../Page/Login";
import moment from "moment";

const AuthWrapper = () => {
    const navigate = useNavigate();
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const checkToken =  () => {
        const token = localStorage.getItem("authToken");
        if (token) {
            const tokenDecoded = jwtDecode(token);
            if (moment.unix(tokenDecoded.exp).isBefore(moment())){
                setIsAuthenticated(false);
            }
        }else{
            setIsAuthenticated(false);
        }
    }

    useEffect(() => {
        checkToken();
    }, [navigate]);
    return isAuthenticated ?
        <Outlet /> : <Login />;
};

export default AuthWrapper;