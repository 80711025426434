import React, {useContext, useEffect, useState} from "react";
import {EmployeAPI} from "../../Services/EmployeAPI";
import {useLocation} from "react-router";
import '../../Styles/infos.css';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InfoPersoEmployeTap from "../../Components/TabEmployes/InfoPersoEmployeTap";
import ActiviteEmployeTab from "../../Components/TabEmployes/ActiviteEmployeTab";
import HabilitationEmployeTab from "../../Components/TabEmployes/HabilitationEmployeTab";
import IndisponibiliteEmployeTab from "../../Components/TabEmployes/IndisponibiliteEmployeTab";
import PlanningHoraires from "../../Components/TabEmployes/PlanningHoraires";
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import SvgIcon from "@mui/material/SvgIcon";
import color from "../../Styles/index";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import {Tooltip} from "@mui/material";
import SelectInput from "../../Components/FormsComponents/SelectInput";
import AtelierEmployeTab from "../../Components/TabEmployes/AtelierEmployeTab";
import Loading from "../../Components/Loading";
function InformationsEmploye() {
    const location = useLocation();
    const [user, setUser] = useState({});
    const navigate = useNavigate()
    const {tabSelected, setTabSelected} = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState(null);
    const [employes, setEmployes] = useState([]);
    const [employeOptions, setEmployeOptions] = useState([]);
    const [indexUser, setIndexUser] = useState(null);
    const [index, setIndex] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUser = async () => {
        try {
            let data = await EmployeAPI.getEmployeById(indexUser.id);
            setUser(data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchEmployes = async () => {
        try {
            const response = await EmployeAPI.getAllEmploye();
            let results = response.sort((a, b) => {
                let nameA = a.nom + ' ' + a.prenom;
                let nameB = b.nom + ' ' + b.prenom;
                return nameA.localeCompare(nameB);
            });
            setEmployes(results);
            return results;
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (event, newValue) => {
        setTabSelected(newValue);
        window.localStorage.setItem("tabSelected",newValue)
    };

    const setOptionEmployes = (data) => {
        let options = [];
        data.map((e) => {
            options.push({value: e, label: e.nom[0].toUpperCase() + e.nom.slice(1).toLowerCase() +' '+ e.prenom[0].toUpperCase() + e.prenom.slice(1).toLowerCase()})
        });
        setEmployeOptions(options)
    }

    useEffect(() => {
        fetchEmployes().then(data => setOptionEmployes(data))
    }, []);

    useEffect(() => {
        if (searchValue != null && searchValue.value != undefined && Array.isArray(employes)){
            let currentIndex = employes.findIndex(p => p.id == searchValue.value.id)
            let user = employes[currentIndex]
            setIndexUser(user)
           navigate("/RH/informations", {state: {id: searchValue.value.id , replace: true, index : currentIndex }});
        }
    }, [searchValue]);

    useEffect(() => {
        if (Array.isArray(employes)){
            let user = employes[location.state.index]
            setIndex(location.state.index)
            setIndexUser(user)
        }
    }, [employes]);

    useEffect(() => {
        if(indexUser != null){
            fetchUser();
        }
    }, [indexUser]);

    useEffect(() => {
        if (index != null){
            if (Array.isArray(employes)){
                let user = employes[index]
                setIndexUser(user)
            }
        }
    }, [index, employes]);

    return <>
        {loading ? <> <Loading />
        </> : <div>
            <div className={"d-flex bg-white justify-content-between"}>
                {/*<Header2Generique title={tabSelected.replace("_", " ")} className={"col-4 align-self-center"}/>*/}
                <h2 className={"col-4 text-center align-self-center"}>{user.nom != undefined && user.nom[0].toUpperCase() + user.nom.slice(1).toLowerCase() +' '+ user.prenom[0].toUpperCase() + user.prenom.slice(1).toLowerCase()} {user.atelier && "("+user.atelier.libelle+")"}</h2>
                <div className={"col-4 d-flex justify-content-end pr-5 align-items-center"}>
                        <SelectInput
                            // classNameLabel={"text-end col-2"}
                            classNameInput={"col-8"}
                            // label="Employes"
                            options={employeOptions}
                            value={searchValue}
                            setObjet={setSearchValue}
                            objet={searchValue}
                            placeholder={"Recherche Employé"}
                        />
                    {/*<div className={"d-flex align-self-center align-items-center"}>*/}
                        {/*<TextField*/}
                        {/*    label={"Rechercher un Employé : "}*/}
                        {/*    sx={{'& .MuiOutlinedInput-root.Mui-focused fieldset': {borderColor: 'var(--orange-color)'}}}*/}
                        {/*    variant={"outlined"}*/}
                        {/*    value={searchValue.value}*/}
                        {/*    name={"value"}*/}
                        {/*    onChange={(e) => {*/}
                        {/*        const {name, value} = e.currentTarget;*/}
                        {/*        setSearchValue({...searchValue, [name] : value})*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<button className="btn" onClick={() => {*/}

                        {/*} }>*/}
                        {/*    <SvgIcon sx={{color: color.orange, fontSize : "4.5rem"}} >*/}
                        {/*        <Search />*/}
                        {/*    </SvgIcon>*/}
                        {/*</button>*/}
                    {/*</div>*/}
                    <Tooltip title={"Employé Précédent"}>
                        <button className="btn"  onClick={() => {
                            setIndex(index >= 1 ? index - 1 : employes.length - 1)
                            navigate("/RH/informations", {state: {id: user.id >= 1 ? user.id - 1 : user.id, index : location.state.index > 1 ? location.state.index - 1 : employes.length - 1}});
                        } }>
                            <SvgIcon sx={{color: color.orange, fontSize : "4.5rem"}} >
                                <ArrowBack />
                            </SvgIcon>
                        </button>
                    </Tooltip>
                    <Tooltip title={"Employé Suivant"}>
                        <button className="btn"  onClick={() => {
                            setIndex(index == employes.length - 1 ? 0 : index + 1)
                            navigate("/RH/informations", {state: {id: user.id + 1, index : location.state.index == employes.length - 1 ? 0 : location.state.index + 1}});
                        } }>
                            <SvgIcon sx={{color: color.orange, fontSize : "4.5rem"}} >
                                <ArrowForward />
                            </SvgIcon>
                        </button>
                    </Tooltip>
                </div>
            </div>

            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={tabSelected}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{
                            "& .MuiTabs-indicator": {
                                backgroundColor: "var(--orange-color)",
                            },
                        }}>
                            <Tab
                                style={{color: tabSelected === "informations_personnelles" ? "var(--orange-color)" : "black"}}
                                label="Informations personnelles" value="informations_personnelles"/>
                            <Tab style={{color: tabSelected === "activites" ? "var(--orange-color)" : "black"}}
                                 label="Activités" value="activites"/>
                            <Tab style={{color: tabSelected === "habilitations" ? "var(--orange-color)" : "black"}}
                                 label="Habilitations" value="habilitations"/>
                            <Tab style={{color: tabSelected === "indisponibilite" ? "var(--orange-color)" : "black"}}
                                 label="Indisponibilité" value="indisponibilite"/>
                            <Tab style={{color: tabSelected === "planning_horaire" ? "var(--orange-color)" : "black"}}
                                 label="Planning Horaire" value="planning_horaire"/>
                            <Tab style={{color: tabSelected === "atelier" ? "var(--orange-color)" : "black"}}
                                 label="Atelier" value="atelier"/>
                        </TabList>
                    </Box>
                    <TabPanel value="informations_personnelles">
                        <InfoPersoEmployeTap user={user} setUser={setUser}/>
                    </TabPanel>
                    <TabPanel value="activites">
                        <ActiviteEmployeTab user={user} />
                    </TabPanel>

                    <TabPanel value="habilitations">
                        <HabilitationEmployeTab user={user} setUser={setUser}/>
                    </TabPanel>

                    <TabPanel value="indisponibilite">
                        <IndisponibiliteEmployeTab user={user}/>
                    </TabPanel>
                    <TabPanel value="planning_horaire">
                        <PlanningHoraires user={user} setUser={setUser}/>
                    </TabPanel>
                    <TabPanel value="atelier">
                        <AtelierEmployeTab user={user} setUser={setUser}/>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>}
    </>;
}

export default InformationsEmploye;
