import React, {useContext, useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, CircularProgress, IconButton, Tooltip,} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../Styles/utilisateurs.css';
import {UtilisateursAPI} from "../../Services/UtilisateursAPI";
import Header2Generique from "../../Components/Headers/Header2Generique";
import GenericDataTable from "../../Components/DataTables/GenericDataTable";
import {ROLES_LIST} from "../../Services/RolesEnum";
import {Visibility} from "@mui/icons-material";
import DotMenuUserDataTable from "../../Components/Menus/DotMenuUserDataTable";
import ModalUser from "../../Components/Modal/ModalUser";
import AuthContext from "../../Context/AuthContext";

const Utilisateurs = () => {
    const {roles} = useContext(AuthContext);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataUtilisateurs, setDataUtilisateurs] = useState([]);
    const [reload, setReload] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [columnUtilisateurs, setColumnUtilisateurs] = useState([
        {
            name: <span className="top-column">Login</span>,
            selector: row => row.login,
            sortable: true
        },
        {
            name: <span className="top-column">Mail</span>,
            selector: row => row.mail,
            sortable: true
        },
        {
            name: <span className="top-column">Role</span>,
            sortable: true,
            selector: row => {
                const role = ROLES_LIST.find(role => role.value === row.roles);
                return role ? role.label : null;
            }
        },
        {
            name: <span className="top-column">Actions</span>,
            selector: row => <div className="d-flex justify-content-evenly">

                {roles.includes('ROLE_ADMIN') && <> <Tooltip title={"Voir l'utilisateur"} placement={"right"}>
                    <IconButton onClick={() => showUser(row.id)}>
                        <Visibility color="warning"/>
                    </IconButton>
                </Tooltip>
                    <DotMenuUserDataTable idUser={row.id} setReload={setReload}/>
                </> }
            </div>
        }
    ]);

    const showUser = id => {
        setSelectedId(id);
        setIsOpen(true);
        setEdit(true);
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const fetchUtilisateurs = async () => {
        let dataUsers = await UtilisateursAPI.getAllUsers();
        setDataUtilisateurs(dataUsers.sort((a, b) => a.login.localeCompare(b.login)));
        setLoading(false)
    }

    const fetchDroits = async () => {
        setLoading(false)
    }

    useEffect(() => {
        if (expanded !== false) {
            setLoading(true);
            if (expanded === 'utilisateurs') {
                fetchUtilisateurs().then(r => '');
            } else if (expanded === 'droits') {
                fetchDroits().then(r => '');
            }
        }
    }, [expanded])

    useEffect(() => {
        if (reload) {
            fetchUtilisateurs().then(r => '');
            setReload(false);
        }
    }, [reload]);


    return <>
        <Header2Generique title={"Utilisateurs"}/>
        <div className="col-11 mx-auto content-accordion">
            <Accordion expanded={expanded === 'utilisateurs'} onChange={handleChange('utilisateurs')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{width: '33%', flexShrink: 0}}>
                        Utilisateurs
                    </Typography>
                    <Typography sx={{color: 'text.secondary'}}>Liste et ajout des utilisateurs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {loading && <div className="text-center"><CircularProgress color={"primary"}/></div>
                        ||
                        <GenericDataTable data={dataUtilisateurs} setReload={setReload} columnItem={columnUtilisateurs}
                                          setIsOpen={setIsOpen} noDataText={"Aucun utilisateur trouvé"}
                                          btnText={"Ajouter un utilisateur"}/>
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'droits'} onChange={handleChange('droits')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography sx={{width: '33%', flexShrink: 0}}>Droit d'accès</Typography>
                    <Typography sx={{color: 'text.secondary'}}>Gestion des droits d'utilisateurs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {loading && <div className="text-center"><CircularProgress color={"primary"}/></div>
                        ||
                        <Typography>Faire le datatable pour gérer les accès</Typography>
                    }
                </AccordionDetails>
            </Accordion>
        </div>
        <ModalUser isOpen={isOpen} setIsOpen={setIsOpen} id={selectedId} setId={setSelectedId} edit={edit}
                   setEdit={setEdit} setReload={setReload}/>
    </>;
};

export default Utilisateurs;