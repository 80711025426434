import React, {useEffect, useState} from 'react';
import './index.css';
import Tooltip from '@mui/material/Tooltip';
import PlanningActiviteAPI from "../../../Services/planningActiviteAPI";
import ModalAjoutActivite from "../../Modal/ModalAjoutActivite";
import DatePicker from "../../DatePicker";
import SelectInput from "../../FormsComponents/SelectInput";
import SvgIcon from "@mui/material/SvgIcon";
import {Cached, Close} from "@mui/icons-material";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {groupeActiviteAPI} from "../../../Services/groupeActiviteAPI";
import ActiviteAPI from "../../../Services/activiteAPI";
import clientAPI from "../../../Services/clientAPI";
import {prestataireAPI} from "../../../Services/prestataireAPI";

const Header2PlanningActivite = ({data, setDataFiltered}) => {
    const [isOpen, setIsOpen] = useState(false);

    const [groupeActivite, setGroupeActivite] = useState({});
    const [activiteRef, setActiviteRef] = useState(null);
    const [optionsGroupeActivite, setOptionsGroupeActivite] = useState([]);
    const [groupeActiviteRef, setGroupeActiviteRef] = useState(null);


    const [client, setClient] = useState({});
    const [optionsClient, setOptionsClient] = useState([]);
    const [clientRef, setClientRef] = useState(null);


    const [activite, setActivite] = useState({});
    const [optionsActivite, setOptionsActivite] = useState([])


    const fetchGroupeActivite = async () => {
        try {
            let response = await groupeActiviteAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };

    const setOptionsSelectGroupeActivite = (ga) => {
        let options = [{value: null, label: "Groupe Activité"}];
        ga.map((groupeA, index) => {
            options.push({value: groupeA, label: groupeA.libelle});
        });
        setOptionsGroupeActivite(options);
    };

    const fetchActivite = async (ga = groupeActivite.value) => {
        if (ga !== undefined && ga !== null) {
            try {
                let response = await ActiviteAPI.fetchAllByGroupeActivite(ga.id);
                return response.data;
            } catch (e) {
                toast.error("Une erreur est survenue");
                console.log(e);
            }
        } else {
            return [];
        }
    };

    const setOptionsSelectActivite = (a) => {
        let options = [{value: null, label: "Activité"}];
        a.map((ac, index) => {
            options.push({value: ac, label: ac.libelle});
        });
        setOptionsActivite(options);
    };

    const fetchClients = async () => {
        try {
            let response = await clientAPI.fetchAll();
            return response.data;
        } catch (e) {
            toast.error("Une erreur est survenue");
            console.log(e);
        }
    };


    const setOptionsSelectClient = (clients) => {
        let options = [{value: null, label: "Clients"}];
        if (clients !== undefined && clients !== null) {
            clients.map((cl, index) => {
                options.push({value: cl, label: cl.nom});
            });
        }
        setOptionsClient(options);
    };

    useEffect(() => {
        fetchGroupeActivite().then((res) => {
            setOptionsSelectGroupeActivite(res);
        })
        fetchClients().then((cls) => {
            setOptionsSelectClient(cls);
        });

    }, []);

    useEffect(() => {
        fetchActivite().then((a) => {
            setOptionsSelectActivite(a);
        });
    }, [groupeActivite]);

    useEffect(() => {
        if (data.length > 0) {
            let options = [];
            let haveGroupeActivite = groupeActivite !== null && Object.keys(groupeActivite).length > 0;
            let haveActivite = activite.activite !== null && Object.keys(activite).length > 0;
            let haveClient = client.client !== null && Object.keys(client).length > 0;
            if (haveGroupeActivite && options.length === 0) {
                options = data.filter((eve) => {
                    return eve.groupeactivite.includes(groupeActivite.label);
                });
            } else if (haveGroupeActivite && options.length > 0) {
                options = options.filter((eve) => {
                    return eve.groupeactivite.includes(groupeActivite.label);
                });
            }

            if (haveActivite && options.length === 0) {
                options = data.filter((eve) => {
                    if (eve.sousactivite3 != null){
                        return eve.activite.includes(activite.label) || eve.sousactivite1.includes(activite.label) || eve.sousactivite2.includes(activite.label) || eve.sousactivite3.includes(activite.label) ;
                    }else if(eve.sousactivite2 != null){
                        return eve.activite.includes(activite.label) || eve.sousactivite1.includes(activite.label) || eve.sousactivite2.includes(activite.label);
                    }else if(eve.sousactivite1 != null){
                        return eve.activite.includes(activite.label) || eve.sousactivite1.includes(activite.label);
                    }else {
                        return eve.activite.includes(activite.label) ;
                    }
                });
            } else if (haveActivite && options.length > 0) {
                options = options.filter((eve) => {
                    if (eve.sousactivite3 != null){
                        return eve.activite.includes(activite.label) || eve.sousactivite1.includes(activite.label) || eve.sousactivite2.includes(activite.label) || eve.sousactivite3.includes(activite.label) ;
                    }else if(eve.sousactivite2 != null){
                        return eve.activite.includes(activite.label) || eve.sousactivite1.includes(activite.label) || eve.sousactivite2.includes(activite.label);
                    }else if(eve.sousactivite1 != null){
                        return eve.activite.includes(activite.label) || eve.sousactivite1.includes(activite.label);
                    }else {
                        return eve.activite.includes(activite.label) ;
                    }
                });
            }

            if (haveClient && options.length === 0) {
                options = data.filter((eve) => {
                    return eve.client === client.client.nom;
                });
            } else if (haveClient && options.length > 0) {
                options = options.filter((eve) => {
                    return eve.client === client.client.nom;
                });
            }

            if (!haveGroupeActivite && !haveActivite && !haveClient && options.length === 0) {
                options = data;
            }
            setDataFiltered(options);
        }
    }, [activite, groupeActivite, client]);

    const handleClick = () => {
        setIsOpen(!isOpen); // Inversion de l'état isOpen lors du clic
    }

    const handleReset = () => {
        groupeActiviteRef.clearValue()
        activiteRef.clearValue()
        clientRef.clearValue()
        setGroupeActivite({})
        setActivite({})
        setClient({})
    }

    function refreshPage() {
        window.location.reload(false);
    }

    return (

        <div className={"header2"}>
            <div className={"entete"}>
                <div className={"box col-12"}>
                    <div className={"d-flex"}>
                        <div className={"col-2 mr-1"}>
                            <SelectInput flex={"column"} placeholder={"Groupe Activité"}
                                         setSelectRef={setGroupeActiviteRef}
                                         objet={groupeActivite}
                                         setObjet={setGroupeActivite} options={optionsGroupeActivite}></SelectInput>
                        </div>
                        <div className={"col-2 mr-1"}>
                            <SelectInput setSelectRef={setClientRef} flex={"column"} name={"client"}
                                         placeholder={"Client"}
                                         objet={client}
                                         setObjet={setClient} options={optionsClient}></SelectInput>
                        </div>
                        <div className={"col-2 mr-1"}>
                            <SelectInput setSelectRef={setActiviteRef} flex={"column"} placeholder={"Activité"}
                                         objet={activite}
                                         setObjet={setActivite}
                                         options={optionsActivite}
                                         disabled={optionsActivite.length > 1 ? false : true}></SelectInput>
                        </div>

                        <div className={"d-flex col-2 offset-2 justify-content-end mr-1"}>
                            <Tooltip title={"Vider les filtres"}>
                                <button className="btn btn-refresh mr-3" onClick={() => handleReset()}>
                                    <SvgIcon sx={{color: "#FFFFFF"}}>
                                    <Close/>
                                    </SvgIcon>
                                </button>
                            </Tooltip>
                            <Tooltip title={"Rafraichir la page"} onClick={refreshPage}>
                                <button className="btn btn-refresh mr-3">
                                    <SvgIcon sx={{color: "#FFFFFF"}}>
                                        <Cached/>
                                    </SvgIcon>
                                </button>
                            </Tooltip>

                        </div>
                        <div className={"d-flex col-2 justify-content-end"}>
                            <Tooltip title={"Ajouter une activité"}>
                            {/* Utilisation de isOpen pour gérer l'ouverture/fermeture du modal */}
                                <button className={"btn btn-add-activity"} onClick={handleClick}>
                                    Ajouter une activité
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {/* Utilisation de isOpen pour conditionnellement afficher le modal */}
                {isOpen && <ModalAjoutActivite isOpen={isOpen} setIsOpen={setIsOpen} onClose={handleClick}/>}
            </div>
        </div>
    );
};

export default Header2PlanningActivite;