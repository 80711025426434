import React from 'react';
import './index.css'

const InputSubmit = ({placeholder, handleSubmit}) => {

    return <div className={"submit-form col-9"} >
        <input type={"submit"} value={placeholder} className={"submit-button col-12"} onClick={handleSubmit}
               onSubmit={handleSubmit} />
    </div>
}

export default InputSubmit;