// export const URL = "http://127.0.0.1:8080";
export const URL = "https://api.merlinlemaillon.fr";
export const URL_API = URL + "/api";

export const LOGIN_CHECK = URL_API + "/auth/login";
export const GROUPE_ACTIVITE_URL = URL_API + "/groupe_activites";
export const ACTIVITE_URL = URL_API + "/activites";
export const PRESTATAIRE_API = URL_API + "/prestataires";
export const PLANNING_ACTIVITE_API = URL_API + "/planning_activites";
export const PLANNING_HORAIRE_API = URL_API + "/planning_horaire";
export const PLANNING_HORAIRE_EXEMPLE_API = URL_API + "/planning_horaire_exemple";
export const REGISTER_API = URL_API + "/auth/register";
export const EMPLOYE_API = URL_API + "/employes";
export const USERS_API = URL_API + "/users";
export const CLIENT_API_URL = URL_API + "/clients";
export const TYPE_EMPLOYE_URL = URL_API + "/type_employes";
export const HABILITATION_URL = URL_API + "/habilitations";
export const RECURRENCE_API = URL_API + "/recurrences";
export const FAITE_API = URL_API + "/faites";
export const INDISPONIBILITE_API = URL_API + "/indisponibilite";
export const TYPE_INDISPONIBILITE_API = URL_API + "/type_indisponibilite";
export const ATELIER_API = URL_API + "/ateliers";
