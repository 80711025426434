import React, {useContext, useState} from 'react';
import {Login as Log, Password} from '@mui/icons-material';
import logoMerlin from '../Assets/img/logo-merlin.png';
import "../Styles/login.css";
import Input from "../Components/FormsComponents/Input";
import InputSubmit from "../Components/FormsComponents/InputSubmit";
import AuthContext from "../Context/AuthContext";
import Auth from "../Services/Auth";
import {toast} from 'react-toastify';

const AuthO = new Auth();
const Login = () => {
    const [user, setUser2] = useState({
        login: "",
        password: ""
    });

    const {setIsAuthenticated, setUser, setRoles, setUuid} = useContext(AuthContext)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await AuthO.authenticate(user);
            setRoles(response.roles)
            setUser(response.login)
            setUuid(response.uuid)
            toast.success("Bonjour " + response.login)
            setIsAuthenticated(true);
        } catch (error) {
            if (error.response != undefined) {
                toast.error(error.response.data.message)
            } else {
                console.log(error)
            }
        }
    }

    return <div className={"login"}>
        <form className={"form"} method={"post"} onSubmit={handleSubmit}>
            <img className={"logo"} alt={"Logo Merlin"} src={logoMerlin}/>
            <Input required={true} flex={"column"} icon={<Log/>} name={"login"} type={"text"} setObjet={setUser2}
                   objet={user} placeholder={"Login"} value={user.login}/>
            <Input required={true} flex={"column"} icon={<Password/>} name={"password"} type={"password"}
                   setObjet={setUser2} objet={user} placeholder={"Password"} value={user.password}/>
            <InputSubmit placeholder={"Connexion"}/>
        </form>
    </div>
}

export default Login;